/* Auth.svelte generated by Svelte v3.42.3 */
import {
	SvelteComponent,
	create_slot,
	detach,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	insert,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot_base
} from "svelte/internal";

import 'regenerator-runtime/runtime';
import { firebase } from './firebase';
import 'firebase/auth';

const get_default_slot_changes = dirty => ({
	user: dirty & /*user*/ 8,
	loggedIn: dirty & /*loggedIn*/ 32,
	rejected: dirty & /*rejected*/ 16
});

const get_default_slot_context = ctx => ({
	user: /*user*/ ctx[3],
	loggedIn: /*loggedIn*/ ctx[5],
	loginWithGoogle: /*loginWithGoogle*/ ctx[1],
	loginWithEmailPassword: /*loginWithEmailPassword*/ ctx[0],
	logout: /*logout*/ ctx[2],
	rejected: /*rejected*/ ctx[4]
});

function create_fragment(ctx) {
	let div;
	let current;
	const default_slot_template = /*#slots*/ ctx[7].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[6], get_default_slot_context);

	return {
		c() {
			div = element("div");
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (default_slot) {
				default_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope, user, loggedIn, rejected*/ 120)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[6],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[6])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[6], dirty, get_default_slot_changes),
						get_default_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let loggedIn;
	let { $$slots: slots = {}, $$scope } = $$props;
	const auth = firebase.auth();
	let user = null;
	let rejected = false;

	const userMapper = claims => ({
		id: claims.user_id,
		name: claims.name,
		email: claims.email,
		picture: claims.picture
	});

	const loginWithEmailPassword = (email, password) => auth.signInWithEmailAndPassword(email, password);

	const loginWithGoogle = () => {
		const provider = new firebase.auth.GoogleAuthProvider();
		return auth.signInWithRedirect(provider);
	};

	const logout = () => auth.signOut();

	auth.onAuthStateChanged(async fireUser => {
		if (fireUser) {
			if (!fireUser.email.endsWith('@propps.com')) {
				$$invalidate(4, rejected = true);
				auth.signOut();
			}

			const token = await fireUser.getIdTokenResult();
			$$invalidate(3, user = userMapper(token.claims));
		} else {
			$$invalidate(3, user = null);
		}
	});

	$$self.$$set = $$props => {
		if ('$$scope' in $$props) $$invalidate(6, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*user*/ 8) {
			$: $$invalidate(5, loggedIn = user !== null);
		}
	};

	return [
		loginWithEmailPassword,
		loginWithGoogle,
		logout,
		user,
		rejected,
		loggedIn,
		$$scope,
		slots
	];
}

class Auth extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			loginWithEmailPassword: 0,
			loginWithGoogle: 1,
			logout: 2
		});
	}

	get loginWithEmailPassword() {
		return this.$$.ctx[0];
	}

	get loginWithGoogle() {
		return this.$$.ctx[1];
	}

	get logout() {
		return this.$$.ctx[2];
	}
}

export default Auth;