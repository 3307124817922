/* columns/Listings.svelte generated by Svelte v3.42.3 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import { onMount } from 'svelte';
import MetricsModule from '../components/MetricsModule.svelte';
import LineChart from '../components/charts/LineChart.svelte';
import { stats } from '../firebase';
import { format } from 'date-fns';
import Percentage from '../components/Percentage.svelte';
import StatRow from '../components/StatRow.svelte';
import { dunno } from '../dunno';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[7] = list[i];
	return child_ctx;
}

// (47:4) {#each months as month}
function create_each_block(ctx) {
	let statrow;
	let current;

	statrow = new StatRow({
			props: {
				title: format(/*month*/ ctx[7].month.toDate(), 'MMMM'),
				subtitle: {
					value: /*month*/ ctx[7].change,
					suffix: 'from prev. month',
					showPercentage: true
				},
				main: { value: /*month*/ ctx[7].count }
			}
		});

	return {
		c() {
			create_component(statrow.$$.fragment);
		},
		m(target, anchor) {
			mount_component(statrow, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const statrow_changes = {};
			if (dirty & /*months*/ 8) statrow_changes.title = format(/*month*/ ctx[7].month.toDate(), 'MMMM');

			if (dirty & /*months*/ 8) statrow_changes.subtitle = {
				value: /*month*/ ctx[7].change,
				suffix: 'from prev. month',
				showPercentage: true
			};

			if (dirty & /*months*/ 8) statrow_changes.main = { value: /*month*/ ctx[7].count };
			statrow.$set(statrow_changes);
		},
		i(local) {
			if (current) return;
			transition_in(statrow.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(statrow.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(statrow, detaching);
		}
	};
}

// (31:0) <MetricsModule heading={'Listings'}>
function create_default_slot(ctx) {
	let div;
	let h2;

	let t0_value = (/*totalActiveListings*/ ctx[0]
	? /*totalActiveListings*/ ctx[0].toLocaleString()
	: dunno()) + "";

	let t0;
	let t1;
	let ul0;
	let li0;
	let t3;
	let percentage;
	let t4;
	let ul1;
	let li1;
	let t6;
	let li2;

	let t7_value = (/*totalListings*/ ctx[2]
	? /*totalListings*/ ctx[2].toLocaleString()
	: dunno()) + "";

	let t7;
	let t8;
	let hr;
	let t9;
	let current;

	percentage = new Percentage({
			props: {
				value: /*monthOnMonth*/ ctx[1],
				suffix: "MoM"
			}
		});

	let each_value = /*months*/ ctx[3];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");
			h2 = element("h2");
			t0 = text(t0_value);
			t1 = space();
			ul0 = element("ul");
			li0 = element("li");
			li0.textContent = "Active listings";
			t3 = space();
			create_component(percentage.$$.fragment);
			t4 = space();
			ul1 = element("ul");
			li1 = element("li");
			li1.textContent = "Total listings";
			t6 = space();
			li2 = element("li");
			t7 = text(t7_value);
			t8 = space();
			hr = element("hr");
			t9 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(ul0, "class", "hero");
			attr(li2, "class", "count");
			attr(ul1, "class", "hero");
			attr(hr, "class", "main");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h2);
			append(h2, t0);
			append(div, t1);
			append(div, ul0);
			append(ul0, li0);
			append(ul0, t3);
			mount_component(percentage, ul0, null);
			append(div, t4);
			append(div, ul1);
			append(ul1, li1);
			append(ul1, t6);
			append(ul1, li2);
			append(li2, t7);
			append(div, t8);
			append(div, hr);
			append(div, t9);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*totalActiveListings*/ 1) && t0_value !== (t0_value = (/*totalActiveListings*/ ctx[0]
			? /*totalActiveListings*/ ctx[0].toLocaleString()
			: dunno()) + "")) set_data(t0, t0_value);

			const percentage_changes = {};
			if (dirty & /*monthOnMonth*/ 2) percentage_changes.value = /*monthOnMonth*/ ctx[1];
			percentage.$set(percentage_changes);

			if ((!current || dirty & /*totalListings*/ 4) && t7_value !== (t7_value = (/*totalListings*/ ctx[2]
			? /*totalListings*/ ctx[2].toLocaleString()
			: dunno()) + "")) set_data(t7, t7_value);

			if (dirty & /*format, months*/ 8) {
				each_value = /*months*/ ctx[3];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(percentage.$$.fragment, local);

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			transition_out(percentage.$$.fragment, local);
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(percentage);
			destroy_each(each_blocks, detaching);
		}
	};
}

function create_fragment(ctx) {
	let metricsmodule;
	let current;

	metricsmodule = new MetricsModule({
			props: {
				heading: 'Listings',
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(metricsmodule.$$.fragment);
		},
		m(target, anchor) {
			mount_component(metricsmodule, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const metricsmodule_changes = {};

			if (dirty & /*$$scope, months, totalListings, monthOnMonth, totalActiveListings*/ 1039) {
				metricsmodule_changes.$$scope = { dirty, ctx };
			}

			metricsmodule.$set(metricsmodule_changes);
		},
		i(local) {
			if (current) return;
			transition_in(metricsmodule.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(metricsmodule.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(metricsmodule, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let totalActiveListings;
	let monthOnMonth;
	let totalListings;
	let months = [];
	let x = { name: 'Months', type: 'string' };

	let y = {
		name: 'Number of listings',
		type: 'number'
	};

	let chartData = [];

	onMount(() => {
		stats.doc('listings').onSnapshot(snapshot => {
			const data = snapshot.data();
			$$invalidate(0, totalActiveListings = data.active.count);
			$$invalidate(1, monthOnMonth = data.active.monthOnMonth);
			$$invalidate(2, totalListings = data.count);
			$$invalidate(3, months = Array.from([...data.months]).reverse());
			chartData = Array.from([...data.months]).map(month => [format(month.month.toDate(), 'MMMM'), month.total]);
		});
	});

	return [totalActiveListings, monthOnMonth, totalListings, months];
}

class Listings extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Listings;