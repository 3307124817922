/* components/charts/LineChart.svelte generated by Svelte v3.42.3 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style
} from "svelte/internal";

import { afterUpdate } from "svelte";

function create_fragment(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			attr(div, "id", "chart_div");
			set_style(div, "min-width", "100%");
			set_style(div, "max-width", "100%");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	google.charts.load("current", { packages: ["corechart", "line"] });
	let { x, y, data } = $$props;

	afterUpdate(() => {
		google.charts.setOnLoadCallback(drawChart);
	});

	function drawChart() {
		const dataTable = new google.visualization.DataTable();
		dataTable.addColumn(x.type, x.name);
		dataTable.addColumn(y.type, y.name);
		dataTable.addRows(data);
		let chartColors = ["#ffffff", "#ffffff", "#ffffff"];

		let rgbaMap = {
			"#ffffff": "rgba(255,255,255.1)",
			"#ffffff": "rgba(255,255,255,0.1)",
			"#ffffff": "rgba(255,255,255,0.1)"
		};

		let options = {
			curveType: "function",
			backgroundColor: "transparent",
			legend: { position: "none" },
			vAxis: {
				gridlines: { color: chartColors[0] },
				minorGridlines: { color: chartColors[0] },
				baselineColor: { color: chartColors[0] },
				textStyle: { fontSize: 12 }
			},
			hAxis: { textStyle: { fontSize: 12 } },
			series: {
				0: {
					color: chartColors[0],
					lineWidth: 1,
					lineDashStyle: [4, 2]
				},
				1: { color: chartColors[2], lineWidth: 2 }
			}
		};

		const chart = new google.visualization.LineChart(document.getElementById("chart_div"));

		// modify svg
		google.visualization.events.addListener(chart, "ready", function () {
			Array.prototype.forEach.call(chart_div.getElementsByTagName("rect"), function (rect) {
				if (chartColors.indexOf(rect.getAttribute("fill")) > -1) {
					rect.setAttribute("fill", rgbaMap[rect.getAttribute("fill")]);
				}
			});
		});

		chart.draw(dataTable, options);
	}

	$$self.$$set = $$props => {
		if ('x' in $$props) $$invalidate(0, x = $$props.x);
		if ('y' in $$props) $$invalidate(1, y = $$props.y);
		if ('data' in $$props) $$invalidate(2, data = $$props.data);
	};

	return [x, y, data];
}

class LineChart extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { x: 0, y: 1, data: 2 });
	}
}

export default LineChart;