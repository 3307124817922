/* columns/Ship.svelte generated by Svelte v3.42.3 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_style,
	space
} from "svelte/internal";

import { stats, firebase } from "../firebase";
import { onMount } from "svelte";

function create_if_block(ctx) {
	return { c: noop, m: noop, d: noop };
}

function create_fragment(ctx) {
	let div2;
	let t0;
	let div1;
	let div0;
	let t1;
	let ul;
	let mounted;
	let dispose;
	let if_block = /*episode*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			div2 = element("div");
			if (if_block) if_block.c();
			t0 = space();
			div1 = element("div");
			div0 = element("div");
			t1 = space();
			ul = element("ul");

			ul.innerHTML = `<li class="svelte-1osbynu"></li> 
    <li class="svelte-1osbynu"></li> 
    <li class="svelte-1osbynu"></li> 
    <li class="svelte-1osbynu"></li>`;

			attr(div0, "class", "ship svelte-1osbynu");

			set_style(div0, "margin-left", /*completed*/ ctx[1]
			? 'calc(' + /*completed*/ ctx[1] + '% - 80px)'
			: 0);

			attr(div1, "class", "progress svelte-1osbynu");
			attr(ul, "class", "markers svelte-1osbynu");
			attr(div2, "class", "ship-progress svelte-1osbynu");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			if (if_block) if_block.m(div2, null);
			append(div2, t0);
			append(div2, div1);
			append(div1, div0);
			append(div2, t1);
			append(div2, ul);

			if (!mounted) {
				dispose = listen(div0, "click", /*click_handler*/ ctx[3]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*episode*/ ctx[0]) {
				if (if_block) {
					
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div2, t0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*completed*/ 2) {
				set_style(div0, "margin-left", /*completed*/ ctx[1]
				? 'calc(' + /*completed*/ ctx[1] + '% - 80px)'
				: 0);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			if (if_block) if_block.d();
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let episode;
	let completed = 0;
	let now = new Date();

	onMount(() => {
		stats.doc('episodes').onSnapshot(snapshot => {
			const data = snapshot.data();
			$$invalidate(0, episode = data['S' + data.current_season].pop());
		});

		setInterval(
			() => {
				$$invalidate(2, now = new Date());
			},
			10 * 60 * 1000
		);
	});

	const click_handler = () => firebase.auth().signOut();

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*episode, now*/ 5) {
			$: if (episode) {
				const start = episode.start.toDate();
				const end = episode.end.toDate();
				$$invalidate(1, completed = Math.round((now - start) / (end - start) * 100));
			}
		}
	};

	return [episode, completed, now, click_handler];
}

class Ship extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Ship;