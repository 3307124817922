/* App.svelte generated by Svelte v3.42.3 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	is_function,
	listen,
	mount_component,
	noop,
	prevent_default,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import queryString from "query-string";
import { onMount } from "svelte";
import Listings from "./columns/Listings.svelte";
import Offers from "./columns/Offers.svelte";
import Buyers from './columns/Buyers.svelte';
import Agents from './columns/Agents.svelte';
import Ship from "./columns/Ship.svelte";
import Auth from './Auth.svelte';

function create_else_block(ctx) {
	let section;
	let t;

	function select_block_type_1(ctx, dirty) {
		if (/*rejected*/ ctx[3]) return create_if_block_2;
		return create_else_block_1;
	}

	let current_block_type = select_block_type_1(ctx, -1);
	let if_block0 = current_block_type(ctx);
	let if_block1 = !/*user*/ ctx[0] && create_if_block_1(ctx);

	return {
		c() {
			section = element("section");
			if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			attr(section, "class", "login svelte-4p0fjo");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			if_block0.m(section, null);
			append(section, t);
			if (if_block1) if_block1.m(section, null);
		},
		p(ctx, dirty) {
			if (current_block_type !== (current_block_type = select_block_type_1(ctx, dirty))) {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(section, t);
				}
			}

			if (!/*user*/ ctx[0]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					if_block1.m(section, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(section);
			if_block0.d();
			if (if_block1) if_block1.d();
		}
	};
}

// (28:4) {#if loggedIn}
function create_if_block(ctx) {
	let section;
	let listings;
	let t0;
	let offers;
	let t1;
	let buyers;
	let t2;
	let agents;
	let t3;
	let ship;
	let current;
	listings = new Listings({});
	offers = new Offers({});
	buyers = new Buyers({});
	agents = new Agents({});
	ship = new Ship({});

	return {
		c() {
			section = element("section");
			create_component(listings.$$.fragment);
			t0 = space();
			create_component(offers.$$.fragment);
			t1 = space();
			create_component(buyers.$$.fragment);
			t2 = space();
			create_component(agents.$$.fragment);
			t3 = space();
			create_component(ship.$$.fragment);
			attr(section, "class", "svelte-4p0fjo");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			mount_component(listings, section, null);
			append(section, t0);
			mount_component(offers, section, null);
			append(section, t1);
			mount_component(buyers, section, null);
			append(section, t2);
			mount_component(agents, section, null);
			append(section, t3);
			mount_component(ship, section, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(listings.$$.fragment, local);
			transition_in(offers.$$.fragment, local);
			transition_in(buyers.$$.fragment, local);
			transition_in(agents.$$.fragment, local);
			transition_in(ship.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(listings.$$.fragment, local);
			transition_out(offers.$$.fragment, local);
			transition_out(buyers.$$.fragment, local);
			transition_out(agents.$$.fragment, local);
			transition_out(ship.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			destroy_component(listings);
			destroy_component(offers);
			destroy_component(buyers);
			destroy_component(agents);
			destroy_component(ship);
		}
	};
}

// (41:8) {:else}
function create_else_block_1(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			attr(div, "class", "logo svelte-4p0fjo");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (38:8) {#if rejected}
function create_if_block_2(ctx) {
	let div;
	let t0;
	let span;

	return {
		c() {
			div = element("div");
			t0 = space();
			span = element("span");
			span.textContent = "Nice try, Jimbo";
			attr(div, "class", "garfield svelte-4p0fjo");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			insert(target, t0, anchor);
			insert(target, span, anchor);
		},
		d(detaching) {
			if (detaching) detach(div);
			if (detaching) detach(t0);
			if (detaching) detach(span);
		}
	};
}

// (44:8) {#if !user}
function create_if_block_1(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Sign in with Google";
			attr(button, "class", "cta-light svelte-4p0fjo");
			attr(button, "type", "button");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", prevent_default(function () {
					if (is_function(/*loginWithGoogle*/ ctx[2])) /*loginWithGoogle*/ ctx[2].apply(this, arguments);
				}));

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (27:2) <Auth let:user let:loggedIn let:loginWithGoogle let:rejected>
function create_default_slot(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*loggedIn*/ ctx[1]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let main;
	let auth;
	let current;

	auth = new Auth({
			props: {
				$$slots: {
					default: [
						create_default_slot,
						({ user, loggedIn, loginWithGoogle, rejected }) => ({
							0: user,
							1: loggedIn,
							2: loginWithGoogle,
							3: rejected
						}),
						({ user, loggedIn, loginWithGoogle, rejected }) => (user ? 1 : 0) | (loggedIn ? 2 : 0) | (loginWithGoogle ? 4 : 0) | (rejected ? 8 : 0)
					]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			main = element("main");
			create_component(auth.$$.fragment);
			attr(main, "class", "svelte-4p0fjo");
		},
		m(target, anchor) {
			insert(target, main, anchor);
			mount_component(auth, main, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const auth_changes = {};

			if (dirty & /*$$scope, loggedIn, loginWithGoogle, user, rejected*/ 31) {
				auth_changes.$$scope = { dirty, ctx };
			}

			auth.$set(auth_changes);
		},
		i(local) {
			if (current) return;
			transition_in(auth.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(auth.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			destroy_component(auth);
		}
	};
}

function instance($$self) {
	onMount(() => {
		let parsed = {};

		if (typeof window !== "undefined") {
			parsed = queryString.parse(window.location.search);
		}

		if (parsed.img) {
			document.querySelector("html").style.backgroundImage = "url(" + parsed.img + ")";
		}
	});

	return [];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;