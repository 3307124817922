/* columns/Offers.svelte generated by Svelte v3.42.3 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import { format, subMonths } from 'date-fns';
import { onMount } from 'svelte';
import MetricsModule from '../components/MetricsModule.svelte';
import Percentage from '../components/Percentage.svelte';
import StatRow from '../components/StatRow.svelte';
import { dunno } from '../dunno';
import { stats } from '../firebase';

function create_default_slot(ctx) {
	let div;
	let h2;

	let t0_value = (/*offersPerListing*/ ctx[0]
	? /*offersPerListing*/ ctx[0].toFixed(1)
	: dunno()) + "";

	let t0;
	let t1;
	let ul0;
	let li0;
	let t3;
	let percentage;
	let t4;
	let ul1;
	let li1;
	let t6;
	let li2;

	let t7_value = (/*totalOffers*/ ctx[2]
	? /*totalOffers*/ ctx[2].toLocaleString()
	: dunno()) + "";

	let t7;
	let t8;
	let hr;
	let t9;
	let statrow0;
	let t10;
	let statrow1;
	let t11;
	let statrow2;
	let t12;
	let statrow3;
	let t13;
	let statrow4;
	let current;

	percentage = new Percentage({
			props: {
				value: /*fromLastMonth*/ ctx[1],
				suffix: `from ${format(subMonths(new Date(), 1), 'MMM')}`
			}
		});

	statrow0 = new StatRow({
			props: {
				title: 'Total value of offers',
				subtitle: {
					value: /*tvof*/ ctx[3]?.change,
					suffix: 'in last 30 days',
					showPercentage: true
				},
				main: /*tvof*/ ctx[3].value
				? formatTotal(/*tvof*/ ctx[3].value)
				: null
			}
		});

	statrow1 = new StatRow({
			props: {
				title: 'Offers in last 30 days',
				subtitle: {
					value: /*oiltd*/ ctx[4]?.change,
					suffix: 'in last 30 days',
					showPercentage: true
				},
				main: /*oiltd*/ ctx[4]?.value
			}
		});

	statrow2 = new StatRow({
			props: {
				title: 'Unique offers per listing',
				subtitle: {
					value: /*uopl*/ ctx[5]?.change,
					suffix: 'in last 30 days',
					showPercentage: true
				},
				main: /*uopl*/ ctx[5]?.value?.toFixed(2)
			}
		});

	statrow3 = new StatRow({
			props: {
				title: 'Most engaged OPL',
				subtitle: /*meopl*/ ctx[6]?.name,
				main: {
					value: /*meopl*/ ctx[6].value?.toFixed(2),
					color: 'green'
				}
			}
		});

	statrow4 = new StatRow({
			props: {
				title: 'Least engaged OPL',
				subtitle: /*leopl*/ ctx[7]?.name,
				main: {
					value: /*leopl*/ ctx[7].value?.toFixed(2),
					color: 'red'
				}
			}
		});

	return {
		c() {
			div = element("div");
			h2 = element("h2");
			t0 = text(t0_value);
			t1 = space();
			ul0 = element("ul");
			li0 = element("li");
			li0.textContent = "Offers per listing";
			t3 = space();
			create_component(percentage.$$.fragment);
			t4 = space();
			ul1 = element("ul");
			li1 = element("li");
			li1.textContent = "Total offers";
			t6 = space();
			li2 = element("li");
			t7 = text(t7_value);
			t8 = space();
			hr = element("hr");
			t9 = space();
			create_component(statrow0.$$.fragment);
			t10 = space();
			create_component(statrow1.$$.fragment);
			t11 = space();
			create_component(statrow2.$$.fragment);
			t12 = space();
			create_component(statrow3.$$.fragment);
			t13 = space();
			create_component(statrow4.$$.fragment);
			attr(ul0, "class", "hero");
			attr(li2, "class", "count");
			attr(ul1, "class", "hero");
			attr(hr, "class", "main");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h2);
			append(h2, t0);
			append(div, t1);
			append(div, ul0);
			append(ul0, li0);
			append(ul0, t3);
			mount_component(percentage, ul0, null);
			append(div, t4);
			append(div, ul1);
			append(ul1, li1);
			append(ul1, t6);
			append(ul1, li2);
			append(li2, t7);
			append(div, t8);
			append(div, hr);
			append(div, t9);
			mount_component(statrow0, div, null);
			append(div, t10);
			mount_component(statrow1, div, null);
			append(div, t11);
			mount_component(statrow2, div, null);
			append(div, t12);
			mount_component(statrow3, div, null);
			append(div, t13);
			mount_component(statrow4, div, null);
			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*offersPerListing*/ 1) && t0_value !== (t0_value = (/*offersPerListing*/ ctx[0]
			? /*offersPerListing*/ ctx[0].toFixed(1)
			: dunno()) + "")) set_data(t0, t0_value);

			const percentage_changes = {};
			if (dirty & /*fromLastMonth*/ 2) percentage_changes.value = /*fromLastMonth*/ ctx[1];
			percentage.$set(percentage_changes);

			if ((!current || dirty & /*totalOffers*/ 4) && t7_value !== (t7_value = (/*totalOffers*/ ctx[2]
			? /*totalOffers*/ ctx[2].toLocaleString()
			: dunno()) + "")) set_data(t7, t7_value);

			const statrow0_changes = {};

			if (dirty & /*tvof*/ 8) statrow0_changes.subtitle = {
				value: /*tvof*/ ctx[3]?.change,
				suffix: 'in last 30 days',
				showPercentage: true
			};

			if (dirty & /*tvof*/ 8) statrow0_changes.main = /*tvof*/ ctx[3].value
			? formatTotal(/*tvof*/ ctx[3].value)
			: null;

			statrow0.$set(statrow0_changes);
			const statrow1_changes = {};

			if (dirty & /*oiltd*/ 16) statrow1_changes.subtitle = {
				value: /*oiltd*/ ctx[4]?.change,
				suffix: 'in last 30 days',
				showPercentage: true
			};

			if (dirty & /*oiltd*/ 16) statrow1_changes.main = /*oiltd*/ ctx[4]?.value;
			statrow1.$set(statrow1_changes);
			const statrow2_changes = {};

			if (dirty & /*uopl*/ 32) statrow2_changes.subtitle = {
				value: /*uopl*/ ctx[5]?.change,
				suffix: 'in last 30 days',
				showPercentage: true
			};

			if (dirty & /*uopl*/ 32) statrow2_changes.main = /*uopl*/ ctx[5]?.value?.toFixed(2);
			statrow2.$set(statrow2_changes);
			const statrow3_changes = {};
			if (dirty & /*meopl*/ 64) statrow3_changes.subtitle = /*meopl*/ ctx[6]?.name;

			if (dirty & /*meopl*/ 64) statrow3_changes.main = {
				value: /*meopl*/ ctx[6].value?.toFixed(2),
				color: 'green'
			};

			statrow3.$set(statrow3_changes);
			const statrow4_changes = {};
			if (dirty & /*leopl*/ 128) statrow4_changes.subtitle = /*leopl*/ ctx[7]?.name;

			if (dirty & /*leopl*/ 128) statrow4_changes.main = {
				value: /*leopl*/ ctx[7].value?.toFixed(2),
				color: 'red'
			};

			statrow4.$set(statrow4_changes);
		},
		i(local) {
			if (current) return;
			transition_in(percentage.$$.fragment, local);
			transition_in(statrow0.$$.fragment, local);
			transition_in(statrow1.$$.fragment, local);
			transition_in(statrow2.$$.fragment, local);
			transition_in(statrow3.$$.fragment, local);
			transition_in(statrow4.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(percentage.$$.fragment, local);
			transition_out(statrow0.$$.fragment, local);
			transition_out(statrow1.$$.fragment, local);
			transition_out(statrow2.$$.fragment, local);
			transition_out(statrow3.$$.fragment, local);
			transition_out(statrow4.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(percentage);
			destroy_component(statrow0);
			destroy_component(statrow1);
			destroy_component(statrow2);
			destroy_component(statrow3);
			destroy_component(statrow4);
		}
	};
}

function create_fragment(ctx) {
	let metricsmodule;
	let current;

	metricsmodule = new MetricsModule({
			props: {
				heading: 'Offers',
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(metricsmodule.$$.fragment);
		},
		m(target, anchor) {
			mount_component(metricsmodule, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const metricsmodule_changes = {};

			if (dirty & /*$$scope, leopl, meopl, uopl, oiltd, tvof, totalOffers, fromLastMonth, offersPerListing*/ 511) {
				metricsmodule_changes.$$scope = { dirty, ctx };
			}

			metricsmodule.$set(metricsmodule_changes);
		},
		i(local) {
			if (current) return;
			transition_in(metricsmodule.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(metricsmodule.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(metricsmodule, detaching);
		}
	};
}

function formatTotal(value) {
	if (value >= 1e9) {
		return `$${(value / 1e9).toFixed(2)}B`;
	}

	return `$${(value / 1e6).toFixed(0)}M`;
}

function instance($$self, $$props, $$invalidate) {
	let offersPerListing;
	let fromLastMonth = null;
	let totalOffers;
	let tvof = { change: null, value: null };
	let oiltd = { change: null, value: null };
	let uopl = { change: null, value: null };
	let meopl = { name: null, value: null };
	let leopl = { name: null, value: null };

	onMount(() => {
		stats.doc('offers').onSnapshot(snapshot => {
			const data = snapshot.data();
			$$invalidate(0, offersPerListing = data.opl.value);
			$$invalidate(1, fromLastMonth = data.opl.change);
			$$invalidate(2, totalOffers = data.count);
			$$invalidate(3, tvof = data.totalValue);
			$$invalidate(4, oiltd = data.countInLast30Days);
			$$invalidate(5, uopl = data.uniqueOpl);
			$$invalidate(6, meopl = data.mostEngagedOpl);
			$$invalidate(7, leopl = data.leastEngagedOpl);
		});
	});

	return [offersPerListing, fromLastMonth, totalOffers, tvof, oiltd, uopl, meopl, leopl];
}

class Offers extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Offers;