/* components/MetricsModule.svelte generated by Svelte v3.42.3 */
import {
	SvelteComponent,
	append,
	attr,
	create_slot,
	detach,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	insert,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out,
	update_slot_base
} from "svelte/internal";

function create_fragment(ctx) {
	let section;
	let h1;
	let t0;
	let t1;
	let section_style_value;
	let current;
	const default_slot_template = /*#slots*/ ctx[3].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[2], null);

	return {
		c() {
			section = element("section");
			h1 = element("h1");
			t0 = text(/*heading*/ ctx[0]);
			t1 = space();
			if (default_slot) default_slot.c();
			attr(h1, "class", "svelte-10blf9z");
			attr(section, "style", section_style_value = `opacity: ${/*disabled*/ ctx[1] ? '0' : '1'}`);
			attr(section, "class", "svelte-10blf9z");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, h1);
			append(h1, t0);
			append(section, t1);

			if (default_slot) {
				default_slot.m(section, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*heading*/ 1) set_data(t0, /*heading*/ ctx[0]);

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 4)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[2],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[2])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[2], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*disabled*/ 2 && section_style_value !== (section_style_value = `opacity: ${/*disabled*/ ctx[1] ? '0' : '1'}`)) {
				attr(section, "style", section_style_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { heading = 'Untitled' } = $$props;
	let { disabled = false } = $$props;

	$$self.$$set = $$props => {
		if ('heading' in $$props) $$invalidate(0, heading = $$props.heading);
		if ('disabled' in $$props) $$invalidate(1, disabled = $$props.disabled);
		if ('$$scope' in $$props) $$invalidate(2, $$scope = $$props.$$scope);
	};

	return [heading, disabled, $$scope, slots];
}

class MetricsModule extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { heading: 0, disabled: 1 });
	}
}

export default MetricsModule;