/* columns/Agents.svelte generated by Svelte v3.42.3 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import MetricsModule from "../components/MetricsModule.svelte";
import Percentage from '../components/Percentage.svelte';
import StatRow from '../components/StatRow.svelte';
import { dunno } from '../dunno';

function create_default_slot(ctx) {
	let div;
	let h2;
	let t1;
	let ul0;
	let li0;
	let t3;
	let percentage;
	let t4;
	let ul1;
	let li1;
	let t6;
	let li2;
	let t8;
	let hr;
	let t9;
	let statrow0;
	let t10;
	let statrow1;
	let t11;
	let statrow2;
	let t12;
	let statrow3;
	let t13;
	let statrow4;
	let current;

	percentage = new Percentage({
			props: { value: fromLastMonth, suffix: "" }
		});

	statrow0 = new StatRow({
			props: {
				title: 'QR scans per agent',
				subtitle: {
					value: null,
					suffix: 'in last 30 days',
					showPercentage: true
				},
				main: null
			}
		});

	statrow1 = new StatRow({
			props: {
				title: 'Offers per agent',
				subtitle: {
					value: null,
					suffix: 'in last 30 days',
					showPercentage: true
				},
				main: null
			}
		});

	statrow2 = new StatRow({
			props: { title: '-', subtitle: '-', main: '-' }
		});

	statrow3 = new StatRow({
			props: { title: '-', subtitle: '-', main: '-' }
		});

	statrow4 = new StatRow({
			props: {
				title: 'Average agent rating',
				subtitle: {
					value: null,
					suffix: 'in last 30 days',
					showPercentage: true
				},
				main: null
			}
		});

	return {
		c() {
			div = element("div");
			h2 = element("h2");
			h2.textContent = `${activeAgents ? activeAgents.toLocaleString() : dunno()}`;
			t1 = space();
			ul0 = element("ul");
			li0 = element("li");
			li0.textContent = "Monthly active agents";
			t3 = space();
			create_component(percentage.$$.fragment);
			t4 = space();
			ul1 = element("ul");
			li1 = element("li");
			li1.textContent = "Total agents";
			t6 = space();
			li2 = element("li");
			li2.textContent = `${totalAgents ? totalAgents.toLocaleString() : dunno()}`;
			t8 = space();
			hr = element("hr");
			t9 = space();
			create_component(statrow0.$$.fragment);
			t10 = space();
			create_component(statrow1.$$.fragment);
			t11 = space();
			create_component(statrow2.$$.fragment);
			t12 = space();
			create_component(statrow3.$$.fragment);
			t13 = space();
			create_component(statrow4.$$.fragment);
			attr(ul0, "class", "hero");
			attr(li2, "class", "count");
			attr(ul1, "class", "hero");
			attr(hr, "class", "main");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h2);
			append(div, t1);
			append(div, ul0);
			append(ul0, li0);
			append(ul0, t3);
			mount_component(percentage, ul0, null);
			append(div, t4);
			append(div, ul1);
			append(ul1, li1);
			append(ul1, t6);
			append(ul1, li2);
			append(div, t8);
			append(div, hr);
			append(div, t9);
			mount_component(statrow0, div, null);
			append(div, t10);
			mount_component(statrow1, div, null);
			append(div, t11);
			mount_component(statrow2, div, null);
			append(div, t12);
			mount_component(statrow3, div, null);
			append(div, t13);
			mount_component(statrow4, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(percentage.$$.fragment, local);
			transition_in(statrow0.$$.fragment, local);
			transition_in(statrow1.$$.fragment, local);
			transition_in(statrow2.$$.fragment, local);
			transition_in(statrow3.$$.fragment, local);
			transition_in(statrow4.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(percentage.$$.fragment, local);
			transition_out(statrow0.$$.fragment, local);
			transition_out(statrow1.$$.fragment, local);
			transition_out(statrow2.$$.fragment, local);
			transition_out(statrow3.$$.fragment, local);
			transition_out(statrow4.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(percentage);
			destroy_component(statrow0);
			destroy_component(statrow1);
			destroy_component(statrow2);
			destroy_component(statrow3);
			destroy_component(statrow4);
		}
	};
}

function create_fragment(ctx) {
	let metricsmodule;
	let current;

	metricsmodule = new MetricsModule({
			props: {
				heading: 'Agents',
				disabled: true,
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(metricsmodule.$$.fragment);
		},
		m(target, anchor) {
			mount_component(metricsmodule, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const metricsmodule_changes = {};

			if (dirty & /*$$scope*/ 1) {
				metricsmodule_changes.$$scope = { dirty, ctx };
			}

			metricsmodule.$set(metricsmodule_changes);
		},
		i(local) {
			if (current) return;
			transition_in(metricsmodule.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(metricsmodule.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(metricsmodule, detaching);
		}
	};
}

let activeAgents = null;
let fromLastMonth = null;
let totalAgents = null;

class Agents extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Agents;