import firebase from 'firebase/app'
import 'firebase/firebase-firestore'
import 'firebase/firebase-functions'

let firestore: firebase.firestore.Firestore | null = null
let functions: firebase.functions.Functions | null = null

// Naive implementation of Firebase init.
// For education purposes. Never store your config in source control!
const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
}

firebase.initializeApp(config)

const db = (): firebase.firestore.Firestore => {
  if (firestore === null) {
    firestore = firebase.firestore()
  }
  return firestore
}

const funcs = (): firebase.functions.Functions => {
  if (functions === null) {
    functions = firebase.app().functions()
  }
  return functions
}

const stats = db().collection('Services').doc('dashboard').collection('stats')

export { db, funcs, firebase, stats }
