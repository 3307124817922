/* components/StatRow.svelte generated by Svelte v3.42.3 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import Percentage from "./Percentage.svelte";
import { dunno } from '../dunno';

function create_else_block(ctx) {
	let span;
	let t_value = ((/*subtitle*/ ctx[1] && /*subtitle*/ ctx[1].value) ?? /*subtitle*/ ctx[1] ?? dunno()) + "";
	let t;
	let span_class_value;

	return {
		c() {
			span = element("span");
			t = text(t_value);

			attr(span, "class", span_class_value = /*subtitle*/ ctx[1] && /*subtitle*/ ctx[1].color
			? /*subtitle*/ ctx[1].color
			: ' ');
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t);
		},
		p(ctx, dirty) {
			if (dirty & /*subtitle*/ 2 && t_value !== (t_value = ((/*subtitle*/ ctx[1] && /*subtitle*/ ctx[1].value) ?? /*subtitle*/ ctx[1] ?? dunno()) + "")) set_data(t, t_value);

			if (dirty & /*subtitle*/ 2 && span_class_value !== (span_class_value = /*subtitle*/ ctx[1] && /*subtitle*/ ctx[1].color
			? /*subtitle*/ ctx[1].color
			: ' ')) {
				attr(span, "class", span_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

// (14:6) {#if subtitle && subtitle.showPercentage}
function create_if_block(ctx) {
	let switch_instance;
	let switch_instance_anchor;
	let current;
	var switch_value = Percentage;

	function switch_props(ctx) {
		return {
			props: {
				value: /*subtitle*/ ctx[1].value,
				suffix: /*subtitle*/ ctx[1].suffix
			}
		};
	}

	if (switch_value) {
		switch_instance = new switch_value(switch_props(ctx));
	}

	return {
		c() {
			if (switch_instance) create_component(switch_instance.$$.fragment);
			switch_instance_anchor = empty();
		},
		m(target, anchor) {
			if (switch_instance) {
				mount_component(switch_instance, target, anchor);
			}

			insert(target, switch_instance_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const switch_instance_changes = {};
			if (dirty & /*subtitle*/ 2) switch_instance_changes.value = /*subtitle*/ ctx[1].value;
			if (dirty & /*subtitle*/ 2) switch_instance_changes.suffix = /*subtitle*/ ctx[1].suffix;

			if (switch_value !== (switch_value = Percentage)) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = new switch_value(switch_props(ctx));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, switch_instance_anchor.parentNode, switch_instance_anchor);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				switch_instance.$set(switch_instance_changes);
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(switch_instance_anchor);
			if (switch_instance) destroy_component(switch_instance, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let ul;
	let li0;
	let t0_value = (/*title*/ ctx[0] || dunno()) + "";
	let t0;
	let t1;
	let br;
	let t2;
	let current_block_type_index;
	let if_block;
	let t3;
	let li1;
	let span;

	let t4_value = ((/*main*/ ctx[2] && /*main*/ ctx[2].value) ?? (/*main*/ ctx[2] && !/*main*/ ctx[2].color
	? /*main*/ ctx[2]
	: dunno()) ?? dunno()) + "";

	let t4;
	let span_class_value;
	let t5;
	let hr;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*subtitle*/ ctx[1] && /*subtitle*/ ctx[1].showPercentage) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div = element("div");
			ul = element("ul");
			li0 = element("li");
			t0 = text(t0_value);
			t1 = space();
			br = element("br");
			t2 = space();
			if_block.c();
			t3 = space();
			li1 = element("li");
			span = element("span");
			t4 = text(t4_value);
			t5 = space();
			hr = element("hr");

			attr(span, "class", span_class_value = /*main*/ ctx[2] && /*main*/ ctx[2].color
			? /*main*/ ctx[2].color
			: ' ');
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, ul);
			append(ul, li0);
			append(li0, t0);
			append(li0, t1);
			append(li0, br);
			append(li0, t2);
			if_blocks[current_block_type_index].m(li0, null);
			append(ul, t3);
			append(ul, li1);
			append(li1, span);
			append(span, t4);
			append(div, t5);
			append(div, hr);
			current = true;
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*title*/ 1) && t0_value !== (t0_value = (/*title*/ ctx[0] || dunno()) + "")) set_data(t0, t0_value);
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(li0, null);
			}

			if ((!current || dirty & /*main*/ 4) && t4_value !== (t4_value = ((/*main*/ ctx[2] && /*main*/ ctx[2].value) ?? (/*main*/ ctx[2] && !/*main*/ ctx[2].color
			? /*main*/ ctx[2]
			: dunno()) ?? dunno()) + "")) set_data(t4, t4_value);

			if (!current || dirty & /*main*/ 4 && span_class_value !== (span_class_value = /*main*/ ctx[2] && /*main*/ ctx[2].color
			? /*main*/ ctx[2].color
			: ' ')) {
				attr(span, "class", span_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if_blocks[current_block_type_index].d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { title } = $$props;
	let { subtitle } = $$props;
	let { main } = $$props;

	$$self.$$set = $$props => {
		if ('title' in $$props) $$invalidate(0, title = $$props.title);
		if ('subtitle' in $$props) $$invalidate(1, subtitle = $$props.subtitle);
		if ('main' in $$props) $$invalidate(2, main = $$props.main);
	};

	return [title, subtitle, main];
}

class StatRow extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { title: 0, subtitle: 1, main: 2 });
	}
}

export default StatRow;