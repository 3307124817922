/* components/Percentage.svelte generated by Svelte v3.42.3 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import { dunno } from '../dunno';

function create_else_block(ctx) {
	let li;
	let t0_value = toPercentage(/*value*/ ctx[0]) + "";
	let t0;
	let t1;
	let t2;

	return {
		c() {
			li = element("li");
			t0 = text(t0_value);
			t1 = space();
			t2 = text(/*suffix*/ ctx[1]);
			attr(li, "class", "");
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, t0);
			append(li, t1);
			append(li, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*value*/ 1 && t0_value !== (t0_value = toPercentage(/*value*/ ctx[0]) + "")) set_data(t0, t0_value);
			if (dirty & /*suffix*/ 2) set_data(t2, /*suffix*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(li);
		}
	};
}

// (16:22) 
function create_if_block_2(ctx) {
	let li;
	let t0_value = toPercentage(/*value*/ ctx[0]) + "";
	let t0;
	let t1;
	let t2;

	return {
		c() {
			li = element("li");
			t0 = text(t0_value);
			t1 = space();
			t2 = text(/*suffix*/ ctx[1]);
			attr(li, "class", "red");
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, t0);
			append(li, t1);
			append(li, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*value*/ 1 && t0_value !== (t0_value = toPercentage(/*value*/ ctx[0]) + "")) set_data(t0, t0_value);
			if (dirty & /*suffix*/ 2) set_data(t2, /*suffix*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(li);
		}
	};
}

// (14:22) 
function create_if_block_1(ctx) {
	let li;
	let t0;
	let t1_value = toPercentage(/*value*/ ctx[0]) + "";
	let t1;
	let t2;
	let t3;

	return {
		c() {
			li = element("li");
			t0 = text("+");
			t1 = text(t1_value);
			t2 = space();
			t3 = text(/*suffix*/ ctx[1]);
			attr(li, "class", "green");
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, t0);
			append(li, t1);
			append(li, t2);
			append(li, t3);
		},
		p(ctx, dirty) {
			if (dirty & /*value*/ 1 && t1_value !== (t1_value = toPercentage(/*value*/ ctx[0]) + "")) set_data(t1, t1_value);
			if (dirty & /*suffix*/ 2) set_data(t3, /*suffix*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(li);
		}
	};
}

// (12:2) {#if !value && value !== 0}
function create_if_block(ctx) {
	let li;

	return {
		c() {
			li = element("li");
			li.textContent = `${dunno()}`;
			attr(li, "class", "");
		},
		m(target, anchor) {
			insert(target, li, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(li);
		}
	};
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (!/*value*/ ctx[0] && /*value*/ ctx[0] !== 0) return create_if_block;
		if (/*value*/ ctx[0] > 0) return create_if_block_1;
		if (/*value*/ ctx[0] < 0) return create_if_block_2;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			div = element("div");
			if_block.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if_block.m(div, null);
		},
		p(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			if_block.d();
		}
	};
}

function toPercentage(dec) {
	return `${(dec * 100).toFixed(1)}%`;
}

function instance($$self, $$props, $$invalidate) {
	let { value } = $$props;
	let { suffix } = $$props;

	$$self.$$set = $$props => {
		if ('value' in $$props) $$invalidate(0, value = $$props.value);
		if ('suffix' in $$props) $$invalidate(1, suffix = $$props.suffix);
	};

	return [value, suffix];
}

class Percentage extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { value: 0, suffix: 1 });
	}
}

export default Percentage;